import { OnboardingRequestDetailModel } from '@shared/api/models';
import { useTranslation } from 'react-i18next';

type Props = {
    request: OnboardingRequestDetailModel;
};

export const RequestHeader = ({ request }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-row gap-base border-b border-border-default pb-base">
            <div className="flex flex-col">
                <div>{request.companyData.companyName}</div>
                <div className="text-text-light">
                    {t('requestHeaderDescription', {
                        requester: 'Not Set',
                        date: new Date(request.createdDate).toDateString()
                    })}
                </div>
            </div>
        </div>
    );
};
