/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetBranchesByCountryCodeResponse,
  GetFilterableBranchesResponse,
  ImportBranchAssignmentsBody,
  ProblemDetails
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



/**
 * Returns branches by country code
 */
export const getBranchesByCountryCode = (
    countryCode: string,
 signal?: AbortSignal
) => {
      
      
      return customMutator<GetBranchesByCountryCodeResponse>(
      {url: `/branches/country/${countryCode}`, method: 'GET', signal
    },
      );
    }
  

export const getGetBranchesByCountryCodeQueryKey = (countryCode: string,) => {
    return [`/branches/country/${countryCode}`] as const;
    }

    
export const getGetBranchesByCountryCodeQueryOptions = <TData = Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError = ErrorType<ProblemDetails>>(countryCode: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBranchesByCountryCodeQueryKey(countryCode);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBranchesByCountryCode>>> = ({ signal }) => getBranchesByCountryCode(countryCode, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(countryCode), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError, TData> & { queryKey: QueryKey }
}

export type GetBranchesByCountryCodeQueryResult = NonNullable<Awaited<ReturnType<typeof getBranchesByCountryCode>>>
export type GetBranchesByCountryCodeQueryError = ErrorType<ProblemDetails>

export const useGetBranchesByCountryCode = <TData = Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError = ErrorType<ProblemDetails>>(
 countryCode: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBranchesByCountryCodeQueryOptions(countryCode,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a list of branches assigned to requests of the current user
 */
export const getFilterableBranches = (
    
 signal?: AbortSignal
) => {
      
      
      return customMutator<GetFilterableBranchesResponse>(
      {url: `/branches`, method: 'GET', signal
    },
      );
    }
  

export const getGetFilterableBranchesQueryKey = () => {
    return [`/branches`] as const;
    }

    
export const getGetFilterableBranchesQueryOptions = <TData = Awaited<ReturnType<typeof getFilterableBranches>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilterableBranches>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFilterableBranchesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFilterableBranches>>> = ({ signal }) => getFilterableBranches(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFilterableBranches>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFilterableBranchesQueryResult = NonNullable<Awaited<ReturnType<typeof getFilterableBranches>>>
export type GetFilterableBranchesQueryError = ErrorType<ProblemDetails>

export const useGetFilterableBranches = <TData = Awaited<ReturnType<typeof getFilterableBranches>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilterableBranches>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFilterableBranchesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Import Branch Country/ZipCode assignment in CSV format
 */
export const importBranchAssignments = (
    importBranchAssignmentsBody: ImportBranchAssignmentsBody,
 ) => {
      
      const formData = new FormData();
formData.append('File', importBranchAssignmentsBody.File)

      return customMutator<void>(
      {url: `/branches/import`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getImportBranchAssignmentsMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importBranchAssignments>>, TError,{data: ImportBranchAssignmentsBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof importBranchAssignments>>, TError,{data: ImportBranchAssignmentsBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof importBranchAssignments>>, {data: ImportBranchAssignmentsBody}> = (props) => {
          const {data} = props ?? {};

          return  importBranchAssignments(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ImportBranchAssignmentsMutationResult = NonNullable<Awaited<ReturnType<typeof importBranchAssignments>>>
    export type ImportBranchAssignmentsMutationBody = ImportBranchAssignmentsBody
    export type ImportBranchAssignmentsMutationError = ErrorType<ProblemDetails>

    export const useImportBranchAssignments = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importBranchAssignments>>, TError,{data: ImportBranchAssignmentsBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof importBranchAssignments>>,
        TError,
        {data: ImportBranchAssignmentsBody},
        TContext
      > => {

      const mutationOptions = getImportBranchAssignmentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    