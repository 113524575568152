/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreateOrUpdateContributorModel,
  GetContributorByIdResponse,
  GetContributorsResponse,
  ProblemDetails
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



/**
 * Returns a list of contributors
 */
export const getContributors = (
    
 signal?: AbortSignal
) => {
      
      
      return customMutator<GetContributorsResponse>(
      {url: `/contributors`, method: 'GET', signal
    },
      );
    }
  

export const getGetContributorsQueryKey = () => {
    return [`/contributors`] as const;
    }

    
export const getGetContributorsQueryOptions = <TData = Awaited<ReturnType<typeof getContributors>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContributors>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetContributorsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContributors>>> = ({ signal }) => getContributors(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContributors>>, TError, TData> & { queryKey: QueryKey }
}

export type GetContributorsQueryResult = NonNullable<Awaited<ReturnType<typeof getContributors>>>
export type GetContributorsQueryError = ErrorType<ProblemDetails>

export const useGetContributors = <TData = Awaited<ReturnType<typeof getContributors>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContributors>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContributorsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new contributor
 */
export const createContributor = (
    createOrUpdateContributorModel: CreateOrUpdateContributorModel,
 ) => {
      
      
      return customMutator<GetContributorByIdResponse>(
      {url: `/contributors`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateContributorModel
    },
      );
    }
  


export const getCreateContributorMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContributor>>, TError,{data: CreateOrUpdateContributorModel}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createContributor>>, TError,{data: CreateOrUpdateContributorModel}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createContributor>>, {data: CreateOrUpdateContributorModel}> = (props) => {
          const {data} = props ?? {};

          return  createContributor(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateContributorMutationResult = NonNullable<Awaited<ReturnType<typeof createContributor>>>
    export type CreateContributorMutationBody = CreateOrUpdateContributorModel
    export type CreateContributorMutationError = ErrorType<ProblemDetails>

    export const useCreateContributor = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContributor>>, TError,{data: CreateOrUpdateContributorModel}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createContributor>>,
        TError,
        {data: CreateOrUpdateContributorModel},
        TContext
      > => {

      const mutationOptions = getCreateContributorMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns a contributor by its id
 */
export const getContributorById = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customMutator<GetContributorByIdResponse>(
      {url: `/contributors/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetContributorByIdQueryKey = (id: string,) => {
    return [`/contributors/${id}`] as const;
    }

    
export const getGetContributorByIdQueryOptions = <TData = Awaited<ReturnType<typeof getContributorById>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContributorById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetContributorByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContributorById>>> = ({ signal }) => getContributorById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContributorById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetContributorByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getContributorById>>>
export type GetContributorByIdQueryError = ErrorType<ProblemDetails>

export const useGetContributorById = <TData = Awaited<ReturnType<typeof getContributorById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContributorById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContributorByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates a contributor
 */
export const updateContributor = (
    id: string,
    createOrUpdateContributorModel: CreateOrUpdateContributorModel,
 ) => {
      
      
      return customMutator<GetContributorByIdResponse>(
      {url: `/contributors/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateContributorModel
    },
      );
    }
  


export const getUpdateContributorMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateContributor>>, TError,{id: string;data: CreateOrUpdateContributorModel}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateContributor>>, TError,{id: string;data: CreateOrUpdateContributorModel}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateContributor>>, {id: string;data: CreateOrUpdateContributorModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateContributor(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateContributorMutationResult = NonNullable<Awaited<ReturnType<typeof updateContributor>>>
    export type UpdateContributorMutationBody = CreateOrUpdateContributorModel
    export type UpdateContributorMutationError = ErrorType<ProblemDetails>

    export const useUpdateContributor = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateContributor>>, TError,{id: string;data: CreateOrUpdateContributorModel}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateContributor>>,
        TError,
        {id: string;data: CreateOrUpdateContributorModel},
        TContext
      > => {

      const mutationOptions = getUpdateContributorMutationOptions(options);

      return useMutation(mutationOptions);
    }
    