import { useGetRequestDetail } from '@shared/api/queries/requests/requests';
import { LoadingAnimation } from '@uag/react-core';
import { useParams } from 'react-router-dom';

import { RequestHeader } from './RequestHeader';

export const Request = () => {
    const { requestId = '' } = useParams();

    const { data: request, isLoading } = useGetRequestDetail(requestId);

    if (isLoading) {
        return <LoadingAnimation />;
    }

    return <div>{request && request.data && <RequestHeader request={request?.data} />}</div>;
};
