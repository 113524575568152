import { BaseLayout } from '@uag/react-core';
import { Outlet } from 'react-router-dom';

export const TeamsBaseRoute = '/requests';

export const Teams = () => {
    return (
        <BaseLayout>
            <div className="flex flex-row">
                <div className="w-96">Teams</div>
                <Outlet />
            </div>
        </BaseLayout>
    );
};
