import { ContributorModel } from '@shared/api/models';
import { useGetContributors } from '@shared/api/queries/contributors/contributors';
import { LoadingAnimation, Select } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

type Props = {
    value: string | null | undefined;
    onChange: (value: string | undefined) => void;
};

const getContributorName = (contributor: ContributorModel) => `${contributor.firstName} ${contributor.lastName}`;

export const ResponsiblePersonCombobox = ({ value, onChange: handleOnChange }: Props) => {
    const { t } = useTranslation();
    const { data: apiContributors, isLoading: areContributorsLoading } = useGetContributors();
    const contributors =
        apiContributors?.data?.map((contributor) => ({
            value: contributor.id,
            label: getContributorName(contributor)
        })) ?? [];

    if (areContributorsLoading) {
        return (
            <div className="p-base">
                <LoadingAnimation />
            </div>
        );
    }
    return (
        <Select
            data={[...contributors]}
            label={t('responsiblePerson')}
            value={value}
            allowDeselect
            clearable
            searchable
            onChange={(value) => handleOnChange(value ? value : undefined)}
        />
    );
};
