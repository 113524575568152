import axios, { AxiosError, RawAxiosRequestConfig } from 'axios';
import qs from 'qs';

const welcomeApiClient = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL
});

// add a second `options` argument here if you want to pass extra options to each generated query
// prettier-ignore
export const customMutator = <T,>(config: RawAxiosRequestConfig, options?: RawAxiosRequestConfig): Promise<T> => {
    
    config.paramsSerializer = {
        serialize (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'});
    }};

    const promise = welcomeApiClient({
        ...config,
        ...options,
    }).then(({ data }) => data);

    return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
