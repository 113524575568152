import { OnboardingRequestModel, OnboardingRequestModelCurrentState, RequestStateType } from '@shared/api/models';
import { useGetRequests } from '@shared/api/queries/requests/requests';
import { BaseLayout, Inbox, InboxGroup, InboxItem, InboxItemType, LoadingAnimation, Select } from '@uag/react-core';
import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { BranchCombobox } from 'shared';
import { ResponsiblePersonCombobox } from 'shared/components/ResponsiblePersonCombobox';

export const RequestsBaseRoute = '/requests';

const getStatus = (state: OnboardingRequestModelCurrentState | undefined) => {
    switch (state) {
        case RequestStateType.Invited:
        case RequestStateType.DataInput:
        case RequestStateType.AdditionalDataInput:
        case RequestStateType.ContractSigning:
        case RequestStateType.CoreSystemActivation:
        case RequestStateType.ServiceActivation:
            return undefined;
        case RequestStateType.SignatureVerification:
        case RequestStateType.DataVerification:
            return 'warning';
        case RequestStateType.Completed:
            return 'success';
        case RequestStateType.Declined:
        case RequestStateType.Aborted:
            return 'alert';
        default:
            throw new Error(`Unknown state: ${state}`);
    }
};

type OnboardingInboxItem = InboxItemType & {
    id: string;
};

const getInboxItem = (data: OnboardingRequestModel): OnboardingInboxItem => ({
    id: data.id,
    name: 'Not assigned',
    company: data.companyData.companyName!,
    place: data.branch?.name,
    time: new Date(data.createdDate),
    status: {
        status: getStatus(data.currentState),
        message: (data.currentState && t(`requestStateType.${data.currentState}`)) ?? ''
    }
});

const getStateFilterValues = () => {
    return Object.values(RequestStateType).map((state) => ({ value: state, label: t(`requestStateType.${state}`) }));
};

export const Requests = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');

    const [stateFilter, setStateFilter] = useState<RequestStateType | undefined | null>();
    const [appliedStateFilter, setAppliedStateFilter] = useState<RequestStateType | undefined | null>();

    const [branchFilter, setBranchFilter] = useState<string | undefined | null>();
    const [appliedBranchFilter, setAppliedBranchFilter] = useState<string | undefined | null>();

    const [responsiblePersonFilter, setResponsiblePersonFilter] = useState<string | undefined | null>();
    const [appliedResponsiblePersonFilter, setAppliedResponsiblePersonFilter] = useState<string | undefined | null>();

    const { data, isLoading, error } = useGetRequests({
        state: appliedStateFilter ?? undefined,
        customerSearchText: searchText ?? undefined,
        branchIds: appliedBranchFilter ? [appliedBranchFilter] : undefined,
        contributorIds: appliedResponsiblePersonFilter ? [appliedResponsiblePersonFilter] : undefined
    });

    const filteredItems = data?.data ?? [];

    const completedItems = filteredItems.filter(
        (isLoading) =>
            isLoading.currentState === RequestStateType.Completed ||
            isLoading.currentState === RequestStateType.Declined
    );

    const ongoingItems = filteredItems.filter((isLoading) => !completedItems.includes(isLoading));

    const itemGroups = [
        { title: t('ongoing'), items: ongoingItems },
        { title: t('completed'), items: completedItems }
    ];

    const StateFilter = (
        <Select
            key="stateFilter"
            data={getStateFilterValues()}
            label={t('requestState')}
            placeholder="Pick value"
            value={stateFilter}
            allowDeselect
            clearable
            searchable
            onChange={(value) => setStateFilter(value as RequestStateType)}
        />
    );

    const BranchFilter = (
        <BranchCombobox key="branchFilter" value={branchFilter} onChange={(value) => setBranchFilter(value)} />
    );

    const ResponsiblePersonFilter = (
        <ResponsiblePersonCombobox
            key="responsiblePersonFilter"
            value={responsiblePersonFilter}
            onChange={(value) => setResponsiblePersonFilter(value)}
        />
    );

    const handleFilterModalClosed = (applyFilters: boolean) => {
        if (applyFilters) {
            setAppliedStateFilter(stateFilter);
            setAppliedBranchFilter(branchFilter);
            setAppliedResponsiblePersonFilter(responsiblePersonFilter);
        } else {
            setStateFilter(appliedStateFilter);
            setBranchFilter(appliedBranchFilter);
            setResponsiblePersonFilter(appliedResponsiblePersonFilter);
        }
    };

    const handleClearFilters = () => {
        setStateFilter(null);
        setBranchFilter(null);
        setResponsiblePersonFilter(null);
    };

    const openItem = (item: OnboardingInboxItem) => {
        navigate(`${RequestsBaseRoute}/${item.id}`);
    };

    if (filteredItems && filteredItems.length > 0 && location.pathname.endsWith(RequestsBaseRoute)) {
        navigate(`${RequestsBaseRoute}/${filteredItems[0].id}`);
    }

    return (
        <BaseLayout>
            <Inbox
                filterModalTitle={t('filterRequests', 'Filter requests')}
                filters={[StateFilter, BranchFilter, ResponsiblePersonFilter]}
                filtersSet={!!appliedStateFilter || !!appliedBranchFilter || !!appliedResponsiblePersonFilter}
                onClearFilters={handleClearFilters}
                onFilterModalClose={handleFilterModalClosed}
                onSearchTextChange={(value) => setSearchText(value)}
            >
                {error && <div>{error.message}</div>}
                {isLoading && <LoadingAnimation />}
                {itemGroups.map((group) => (
                    <InboxGroup key={group.title} title={group.title}>
                        {group.items.map((item, index) => (
                            <InboxItem
                                key={index}
                                data={getInboxItem(item)}
                                isSelected={location.pathname.endsWith(item.id)}
                                onClick={openItem}
                            />
                        ))}
                    </InboxGroup>
                ))}
            </Inbox>
            <div className="w-full p-base">{!isLoading && <Outlet />}</div>
        </BaseLayout>
    );
};
